import {
    FolderOutlined,
    DatabaseOutlined,
    TeamOutlined,
    UserOutlined,
    SearchOutlined,
    ScheduleOutlined,
    DollarCircleOutlined,
    FieldTimeOutlined,
    CheckCircleOutlined,
    HourglassOutlined,
    PlusOutlined,
    ReadOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    SwapOutlined,
    BarChartOutlined,
    CopyFilled
} from '@ant-design/icons'

const sidebar = [
    {
        routeName: "frontend.admin.postsIndex",
        icon:  <CopyFilled />,
        routeParams: {},
        type: 'sub',
        children: [
            {
                routeName: "frontend.admin.postCategories.index",
                icon: <HourglassOutlined />,
                routeParams: {}
            },
            {
                routeName: "frontend.admin.posts.index",
                icon: <CheckCircleOutlined /> ,
                routeParams: {}
            },
            {
                routeName: "frontend.admin.posts.create",
                icon: <PlusOutlined /> ,
                routeParams: {}
            }
        ]
    },
    
    {
        routeName: "frontend.admin.usersIndex",
        icon: <UserOutlined/>,
        type: 'sub',
        routeParams: {},
        children: [
            {
                routeName: "frontend.admin.users.index",
                icon: <UserOutlined/>,
                routeParams: {}
            },
        ]
    },
    {
        routeName: "frontend.admin.contactsIndex",
        icon: <UserOutlined/>,
        type: 'sub',
        routeParams: {},
        children: [
            {
                routeName: "frontend.admin.contacts.index",
                icon: <UserOutlined/>,
                routeParams: {}
            },
        ]
    }


    
   
]

export default sidebar